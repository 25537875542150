import React from "react"

import SEO from "../../components/seo"
import styles from "./styles.module.scss"
import Header from "../../components/header"
import { navigate } from "gatsby"

import { useGlobalState, dispatch } from "../../states/user-state"
import Confetti from "../../components/confetti"

export default function InstitutePage() {
  const [value] = useGlobalState("user")
  const { firstName, birthday } = value

  const setFirstName = event =>
    dispatch({
      firstName: String(event.currentTarget.value).toLowerCase(),
      type: "setFirstName",
    })

  const setBirthday = event =>
    dispatch({
      birthday: event.currentTarget.value,
      type: "setBirthday",
    })

  const checkIfInputIsValid = () => {
    if (firstName === "") {
      document.getElementById("firstName").style = "border: 1px solid red"
    } else if (birthday === "") {
      document.getElementById("birthday").style = "border: 1px solid red"
    } else {
      if (process.env.NODE_ENV === "production") {
        storeDataInGoogleSheets()
      } else {
        navigate("/departments/reception/")
      }
    }
  }

  const storeDataInGoogleSheets = () => {
    document.getElementById("enter").disabled = true

    fetch(process.env.USERS_API_URL, {
      method: "POST",
      body: JSON.stringify({
        name: firstName,
        birthday: birthday,
      }),
    })
      .then(() => {
        document.getElementById("enter").disabled = false
      })
      .then(() => {
        navigate("/departments/reception/")
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }

  return (
    <>
      <SEO title="Enter the institute" />
      <Header />
      <Confetti />
      <section className={styles.institute}>
        <div className={styles.institute__container}>
          <div className={styles.institute__container__form}>
            <p>
              Anyone with a date of birth is accepted and invited to the
              institute!
            </p>
            <p>Do you meet this requirement?</p>
            <label htmlFor="firstName">
              <input
                id="firstName"
                placeholder="your name"
                value={firstName}
                onChange={setFirstName}
              ></input>
            </label>
            <label htmlFor="birthday">
              <input
                type="date"
                id="birthday"
                placeholder="dd/mm/yyyy"
                value={birthday}
                onChange={setBirthday}
              ></input>
            </label>
            <button id="enter" onClick={checkIfInputIsValid}>
              enter the institute
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
